.screen-login-locked {
    .clock {
        margin: 0px 0px 60px 20px;
        color: white;
        font-weight: 380;

        .clock-time {
            font-size: 60px;
        }

        .clock-date {
            font-size: 30px;
        }
    }
}

.expand-2 {
    flex: 2;
}