@import "global";
@import "layout";
@import "setup";
@import "login";
@import "password";
@import "user_list";
@import "menu";

.screen-off {
  background-color: black;
  cursor: none;
}

.screen-manager {
  // This is sometimes visible (flashes), when a background image is loading
  background-color: black;
}

.screen-cover {
  background-color: white;
}
