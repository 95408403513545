$button-size  : 25px;
$user-img-size: 200px;

.password-dialog {
    .user-icon {
        margin: 0px auto 20px auto;
        width : $user-img-size;

        img {
            width  : $user-img-size;
            height : $user-img-size;
            opacity: 0.8;
        }
    }

    .user-name {
        font-size    : 30px;
        color        : white;
        text-align   : center;
        margin-bottom: 20px;
        cursor: default;
    }

    .password-div {
        margin : auto;
        width  : 300px;
        // TODO: Color is different when focused
        border : 2px solid #ddd;

        .button {
            div, img {
                display: block;
                height : $button-size;
                width  : $button-size;
                padding: 3px;
                box-sizing: border-box;
                cursor : pointer;
            }

            &.login {
                background-color: #fff4;
            }

            &.reveal {
                margin-right: 5px;
            }
        }

        .password-wrapper {
            background-color: white;
            flex            : 1;
            font-size       : 22px;
            min-width       : 0;
        }

        input {
            flex  : 1;
            border: 0;
        }
    }

    .reset-password {
        margin-top: 15px;
        text-align: center;

        span {
            color    : white;
            font-size: 15px;
            cursor   : pointer;
        }
    }
}
