$button-size: 20px;

.menu-bar {
    position: absolute;
    bottom  : 40px;
    right   : 30px;
    display : table;

    .menu-wrapper {
        display: table-cell;
    }
}

.menu-trigger {
    user-select: none !important;
    padding    : 5px;
    width      : $button-size;
    height     : $button-size;

    img {
        width : 100%;
        height: 100%;
    }
}

.menu-trigger-selected {
    background-color: #444a;
}

.menu-popup {
    position: absolute;
    bottom  : 30px;
    right   : 0px;
}

$menu-pad-x: 10px;
$menu-pad-y: 3px;

.menu {
    display         : table;
    background-color: #555;
    border          : 1px solid #222;
    font-size       : 15px;
    color           : white;
    cursor          : default;

    .menu-item {
        display: table-row;

        .icon {
            display: table-cell;
            width  : $button-size;
            height : $button-size;
            padding: $menu-pad-y 0px $menu-pad-y $menu-pad-x;
        }

        .name {
            display       : table-cell;
            vertical-align: middle;
            padding       : $menu-pad-y $menu-pad-x;
            white-space   : nowrap;
        }
    }
}

.accessibility-menu {
    padding: 15px;
    width: 200px;
    background-color: white;
    cursor: default;

    .switch-bar {
        .title {
            color: gray;
        }

        .state {
            padding: 10px 0px;
        }
    }

    .normal {
        padding: 15px 0px;
    }
}